import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Heading, Image } from 'theme-ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Heading as="h1" variant="styles.h1" sx={{
      marginY: 2
    }} mdxType="Heading">
  Husbanding, Fathering, Golfing and Developing
    </Heading>
    <p><strong parentName="p">{`...my way to retirement!!`}</strong>{`  Since I've obviously got a ways to go, I do my best to keep busy. When I'm not out on the course or `}<em parentName="p">{`playing axeball in other room`}</em>{`  I'm generally reading about some new (to me) languages and frameworks that have piqued my interest.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      