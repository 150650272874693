import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://kenjdavidson.com/react-native-bluetooth-classic"
      }}>{`React Native Bluetooth Classic`}</a></p>
    <p>{`Provide Bluetooth Classic RFCOMM support to both Android and IOS (External Acessory).`}</p>
    <p><a parentName="p" {...{
        "href": "https://kenjdavidson.com/jimbos-nfl-pool/"
      }}>{`Jimbos NFL Pools`}</a></p>
    <p>{`Live weekly scoring for Jimbo's NFL Spread pool.  Currently a work in progress as Jimbo likes to change the source Excel file formats.`}</p>
    <p><a parentName="p" {...{
        "href": "https://kenjdavidson.com/gspro-courses"
      }}>{`GSPro Courses`}</a></p>
    <p>{`I've learned that regardless of my terrible artistic talent, it's extremely relaxing to build local courses to play on GSPro.  The process is pretty straight forward although a little time consuming based on your level of perfection.  Feel free to check out any of my courses, they aren't the best but they work!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      